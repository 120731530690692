(function ($) {
  Drupal.behaviors.fullWidthPromoWithCtaMobileV1 = {
    attach: function (context, settings) {
      $('.full-width-promo-with-cta-mobile-v1').each(function () {
        var undef;
        var $tpl = $(this);
        var link = $tpl.find('.button').attr('href');

        if (link !== undef) {
          $tpl.once().on('click', function() {
            window.location.href = link;
          });
        }
      });
    }
  };
})(jQuery);
